.form-elem-container {
  padding: 20px 30px 0;
}

.form-page {
  .form-card-title {
    display: flex;
    height: 50px;
    padding: 0 30px;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(233, 233, 246);

    .form-card-label {
      color: rgb(52, 57, 161);
      font-weight: bold;
    }
  }

  .form-input-elem {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f5f5f5;
    flex-wrap: wrap;
    border-bottom: none;
  }

  .form-card-content {
    padding: 1em;

    >div {
      padding: 0.5em 1em;
    }
  }

  .bottom-dashed {
    &::after {
      width: 100%;
      content: '';
      margin-bottom: -5px;
      display: inline-block;
      border-bottom: 1px #E5E5E5 dashed;
    }
  }
}

.form-head-container {
  top: 60px;
  z-index: 100;
  position: sticky;
  border-bottom: 1px #E5E5E5 solid; 
  background-color: #F3F5F7;
  padding: 0 30px;
  
  .form-head {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
