@mixin label {
  color: #4a4a4a;
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bolder;
}

@mixin value {
  color: #9c9eb9;
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
}

.detail-container {
  padding: 50px 0;

  .detail-row {
    min-width: 582px;
    max-width: 1024px;
    margin: 0 auto;
    border-bottom: 1px solid #f5f5f5;
    padding: 15px 0;

    .label {
      @include label;
      width: 49%;
      display: inline-block;
    }

    .value {
      @include value;
      width: 50%;
      // text-align: right;
      display: inline-block;
    }
  }

  .detail-table-row {
    margin: 0;
    padding: 10px 0 0 30px;
    border-bottom: 1px solid #f5f5f5;

    .label {
      @include label;
    }

    .value {
      @include value;
    }
  }
}
