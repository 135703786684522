.detail-page {
  .detail-card-title {
    padding: 1em 2em;
    font-weight: bold;
    color: rgb(52, 57, 161);
    background-color: rgb(233, 233, 246);
  }
  
  .detail-card-content {
    display: flex;
    flex-wrap: wrap;
    padding: 0 2em;
    justify-content: space-between;
    border-bottom: 1px solid #f5f5f5;
  
    .detail-card-row {
      display: flex;
      padding: 1em 0;
      flex-basis: 100%;
      justify-content: space-between;
      border-bottom: 1px solid #f5f5f5;
    }
  
    .detail-card-row-two-columns {
      @extend .detail-card-row;
  
      flex-basis: 47%;
    }
  
    .detail-card-label {
      font-size: 1.2em;
    }
  
    .detail-card-row-label {
      white-space: nowrap;
    }
    
    .detail-card-row-value {
      font-weight: bold;
      line-break: anywhere;
    }
  }
  
  .preview-image {
    height: 80px;
    width: 80px;
    display: flex;
    overflow: hidden;
  }
  
}