.color-picker {
  display: flex;
  align-items: center;
  margin-top: 25px;
  justify-content: space-between;

  .color-circle {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px #C8C8C8 solid;
  }
}

.content-title {
  padding: 15px 30px;
}

.content-title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;

  border-top: 1px #E5E5E5 solid;

  h4 {
    width: 80%;
  }

  div {
    height: 40px;
  }

  .content-delete-btn {
    color: #DE583A;
    display: flex;
    align-items: center;
    font-size: 0.8em;
    
    svg {
      font-size: 1.2em;
    }
  }
}

.modal-container {
  display: block;

  .modal-title {
    padding: 20px 0 20px 20px;
    border-bottom: 1px #E5E5E5 solid;
  }

  .modal-image {
    width: 80px;
    height: 80px;
    border-radius: 10px;
  }

  .modal-content-row {
    display: flex;
    padding: 20px;
    justify-content: space-between;
    border-bottom: 1px #E5E5E5 solid;
  }

  .checked-background-color {
    background-color: #ECECF7;
  }
}
