.detail-card-content-head {
  margin: 0.8em 0 0.8em 2em;

  &:not(:first-of-type)::before {
    display: block;
    content: '';
    width: 100%;
    height: 1px;
    background-color: #E5E5E5;
    margin-bottom: 1em;
  }
}
