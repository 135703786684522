.meal-plan-schedule {
  width: 100%;

  .container {
    width: 100%;
    display: flex;
    flex-direction: row;

    .previous, .next {
      width: 8%;
      display: flex;
      align-items: center;
    }
    
    .content {
      width: 84%;
    }
  }
}