.form-container {
  padding: 30px;
  text-align: center;
  .avatar {
    width: 106px;
    height: 106px;
    margin: 0 auto;
  }
  .name {
    font-size: 24px;
    font-weight: bold;
    color: black;
    margin-top: 15px;
  }
  .info {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    margin: 15px 0;
    color: #9c9eb9;
    .divide-dot {
      display: inline-block;
      height: 3px;
      width: 3px;
      border-radius: 50%;
      vertical-align: middle;
      background: #9c9eb9;
      margin-left: 5px;
      margin-right: 5px;
    }
  }
  .info-item {
    border-bottom: 1px solid #f5f5f5;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px 10px;
    width: 80%;
    max-width: 1000px;
    min-width: 582px;
    text-align: left;
    margin: 0 auto;
    .label {
      color: #4a4a4a;
      font-size: 18px;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: bolder;
      display: inline-block;
      width: 25%;
      text-align: left;
    }
    .value {
      font-size: 14px;
      font-family: Arial, Helvetica, sans-serif;
      display: inline-block;
      width: 90%;
      text-align: right;
      .text {
        text-align: right;
      }
    }
  }
  .save-btn {
    margin-top: 20px;
    width: 80%;
    max-width: 1000px;
    min-width: 582px;
  }

  .form-item {
    @extend .save-btn;
    margin: 1em auto 0 auto;
  }
}
